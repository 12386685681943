body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ContainerClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh;
}

.PaperClass {
    margin-top: 40px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
}

.Title {
    padding: 30px;
}

.TitleGrid {
    padding: 30px;
    width: 100%;
    /*overflow: auto;*/
}

.ButtonLogin {
    width: 100%;
    margin-top: 50px;
    padding: 20px;
}

.ForgotPasswordLink {
    width: 100%;
    display: flex;
    margin-top: -15px;
    margin-right: 45px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 1;
}

.RegistrationLink {
    padding-top: 70px;
}

.ProjectsList {
    width: 100%;
    overflow: auto;
}

.LoginInputText {
    height: 70px;
    width: 85%;
    overflow: auto;
}
.ContainerClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh;
}

.PaperClass {
    margin-top: 40px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
}

.Title {
    padding: 30px;
}

.Description {
    padding: 30px;
}

.TitleGrid {
    padding: 30px;
    width: 100%;
    /*overflow: auto;*/
}

.Button {
    width: 100%;
    margin-top: 10px;
    padding: 20px;
}

.EmailInputText {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.BackLink {
    width: 100%;
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.ContainerClassSingUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh;
}

.PaperClassSingUp {
    margin-top: 40px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
}

.TitleSingUp {
    padding: 10px;
}

.DescriptionSingUp {
    padding: 30px;
    /*margin-left: 30px;*/
}

.TitleGridSingUp {
    padding: 30px;
    width: 100%;
    /*overflow: auto;*/
}

.ButtonSingUp {
    width: 100%;
    padding: 20px;
}

.EmailInputTextSingUp {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.UsernameInputTextSingUp {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.PasswordTextSingUp {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.BackLinkSingUp {
    width: 100%;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
