.ContainerClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh;
}

.PaperClass {
    margin-top: 40px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
}

.Title {
    padding: 30px;
}

.Description {
    padding: 30px;
}

.TitleGrid {
    padding: 30px;
    width: 100%;
    /*overflow: auto;*/
}

.Button {
    width: 100%;
    margin-top: 10px;
    padding: 20px;
}

.EmailInputText {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.BackLink {
    width: 100%;
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}