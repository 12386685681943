.ContainerClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh;
}

.PaperClass {
    margin-top: 40px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
}

.Title {
    padding: 30px;
}

.TitleGrid {
    padding: 30px;
    width: 100%;
    /*overflow: auto;*/
}

.ButtonLogin {
    width: 100%;
    margin-top: 50px;
    padding: 20px;
}

.ForgotPasswordLink {
    width: 100%;
    display: flex;
    margin-top: -15px;
    margin-right: 45px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 1;
}

.RegistrationLink {
    padding-top: 70px;
}

.ProjectsList {
    width: 100%;
    overflow: auto;
}

.LoginInputText {
    height: 70px;
    width: 85%;
    overflow: auto;
}