.ContainerClassSingUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh;
}

.PaperClassSingUp {
    margin-top: 40px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
}

.TitleSingUp {
    padding: 10px;
}

.DescriptionSingUp {
    padding: 30px;
    /*margin-left: 30px;*/
}

.TitleGridSingUp {
    padding: 30px;
    width: 100%;
    /*overflow: auto;*/
}

.ButtonSingUp {
    width: 100%;
    padding: 20px;
}

.EmailInputTextSingUp {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.UsernameInputTextSingUp {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.PasswordTextSingUp {
    height: 70px;
    width: 85%;
    overflow: auto;
}

.BackLinkSingUp {
    width: 100%;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}